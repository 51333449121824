@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit",sans-serif;

}
body{
  background: black;
}
h1,h4,p ,a{
  color: aliceblue;
  text-decoration: none;
}
ul{
  list-style-type: none;
  
}
.btn { 
  padding: 12px 32px; 
  font-size: 1rem; 
  text-transform: uppercase; 
  background: yellow; 
  color: #222; 
  border: 1px solid #fff; 
  font-weight: 600; 
  cursor: pointer;
  
}
.btn-light { /* background: rgba(255, 255, 255, 0.2); */ 
  background: transparent; 
  padding: 12px 32px; 
  color: #fff; 
  border: solid 1px #fff;
} 
  .btn:hover { 
    background: Orgba(255, 255, 255, 0. 2);  
    
    color: #fff; 
    transition: 0.3s; 
  }