.pro-heading{
    text-align: center;
    padding: 4px, 4rem, 2px,4rem;
}
h1{
    padding-top: 40px;
    padding-bottom: 30px;
}
.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
    

}
.project-card{
    background: rgb(35, 33, 33);
    padding: 1.2rem 1rem;
    border: 1px solid rgb(225, 222, 169);
}
.project-card img{
    width: 100%;

}
.project-title{
    color: white;
    padding: 1rem 0;;
}
.pro-detail p{
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}
.pro-btn{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}
.pro-btn .btn{
    padding: 0.5rem 1rem;
}
@media  screen and (max-width: 740px) {
    .project-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }

    
}